/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

export function ColorField ({value, onChange, colors}) {
	let colors2 = colors || ["#A93226", "#7D3C98", "#2E86C1", "#229954", "#D4AC0D", "#A6ACAF"];
	return <div>
		<div className="form-group">
			<label>Цвет</label>
			<div className="d-flex">
				{colors2.map ((color, i) => {
					return <div
						key={i} className={`border p-3 ${value == color ? "border-primary" : ""}`}
						style={{backgroundColor: color}}
						onClick={() => onChange ({value: color})}
					/>;
				})}
			</div>
		</div>
	</div>;
}
