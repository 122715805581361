/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import React from "react";
import {Link} from "react-router-dom";
import {useState, useEffect, useRef} from "react";
import PageTitle from "./PageTitle";
import {
	Action,
	Grid,
	Loading,
	Tabs,
	Tab,
	BooleanField,
	createReport,
	reportStyles,
	getTimestampString,
	i18n,
	StringField
} from "objectum-react";
import mediator, {store} from "../modules/mediator";
import PedagogDeclaration from "./PedagogDeclaration";
import ChiefDeclaration from "./ChiefDeclaration";
import { ColorField } from "./ColorField.js"

function Report ({gridEl, expertMap}) {
	let [selected, setSelected] = useState ({});
	let columns = [
		{model: "declaration", property: "id"},
		{model: "declaration", property: "state"},
		{model: "declaration", property: "category"},
		{model: "declaration", property: "date"},
		{model: "declaration", property: "exam", label: "Экзамен педагога"},
		{model: "declaration", property: "chiefExam", label: "Экзамен руководителя"},
		{model: "declaration", property: "examScore"},
		{model: "declaration", property: "comment"},
		{model: "objectum.user", property: "login"},
		{model: "objectum.user", property: "email"},
		{model: "objectum.user", property: "snils"},
		{model: "objectum.user", property: "regDate"},
		{model: "emp", property: "fio", label: "Ф.И.О."},
		{model: "emp", property: "surname"},
		{model: "emp", property: "forename"},
		{model: "emp", property: "patronymic"},
		{model: "emp", property: "sex"},
		{model: "emp", property: "citizenship"},
		{model: "emp", property: "birthdate"},
		{model: "emp", property: "birthplace"},
		{model: "emp", property: "phone"},
		{model: "emp", property: "address"},
		{model: "emp", property: "regAddress"},
		{model: "emp", property: "allegedOrg"},
		{model: "emp", property: "allegedPost"},
		{model: "emp", property: "org"},
		{model: "emp", property: "terr"},
		{model: "emp", property: "post"},
		{model: "emp", property: "pedExp"},
		{model: "emp", property: "category"},
		{model: "emp", property: "submitCategory"},
		{model: "emp", property: "scienceDegree"},
		{model: "emp", property: "degreeDate"},
		{model: "emp", property: "degreeCity"},
		{model: "emp", property: "degreeRegNum"},
		{model: "emp", property: "degreeTheme"},
		{model: "emp", property: "scienceRank"},
		{model: "emp", property: "rankDate"},
		{model: "emp", property: "rankRegNum"},
		{model: "emp", property: "rankTheme"},
		{model: "t.emp.edu", property: "orgStr"},
		{model: "t.emp.edu", property: "startYear"},
		{model: "t.emp.edu", property: "endYear"},
		{model: "t.emp.edu", property: "qualificationStr"},
		{model: "t.emp.retraining", property: "name"},
		{model: "t.emp.retraining", property: "hours"},
		{model: "t.emp.retraining", property: "startDate"},
		{model: "t.emp.retraining", property: "endDate"},
		{model: "t.emp.retraining", property: "place"},
		{model: "t.emp.retraining", property: "num"},
		{model: "t.emp.retraining", property: "regNum"},
		{model: "t.emp.reward", property: "level"},
		{model: "t.emp.reward", property: "reward"},
		{model: "t.emp.reward", property: "url"},
		{model: "t.emp.training", property: "name"},
		{model: "t.emp.training", property: "startDate"},
		{model: "t.emp.training", property: "endDate"},
		{model: "t.emp.training", property: "docNum"},
		{model: "t.emp.training", property: "regNum"},
		{model: "t.emp.training", property: "hours"},
		{model: "t.emp.training", property: "place"},
		{model: "t.emp.eduActResult", property: "eduActResult"},
		{model: "t.emp.personalContribution", property: "personalContribution"},
		{model: "t.emp.personalContribution", property: "url"},
		{model: "t.emp.crime", property: "docType"},
		{model: "t.emp.crime", property: "comment"},
		{model: "t.emp.language", property: "language"},
		{model: "t.emp.language", property: "languageLevel"},
		{model: "t.emp.election", property: "docType"},
		{model: "t.emp.job", property: "place"},
		{model: "t.emp.job", property: "postStr"},
		{model: "t.emp.job", property: "reason"},
		{model: "t.emp.job", property: "startDate"},
		{model: "t.emp.job", property: "endDate"}
	];
	async function build ({progress}) {
		let opts = gridEl.current.prepareRequestOptions ();
		delete opts.offset;
		delete opts.limit;
		let recs = await store.getRecs (opts);

		if (!recs.length) {
			throw new Error ("Заявления отсутствуют.");
		}
		progress ({label: "Загрузка данных"});

		let data = {
			declaration: await store.getRecords ({
				model: "declaration",
				filters: [["id", "in", recs.map (rec => rec.id)]]
			})
		};
		let emps = data.declaration.map (record => record.emp);

		data.emp = await store.getRecords ({
			model: "emp",
			filters: [["id", "in", emps]]
		});
		let models = [], modelPropertyNum = {};

		columns.forEach (c => {
			if (selected [c.id]) {
				if (models.indexOf (c.model) == -1) {
					models.push (c.model);
				}
				modelPropertyNum [c.model] = modelPropertyNum [c.model] || 0;
				modelPropertyNum [c.model] ++;
			}
		});
		for (let i = 0; i < models.length; i ++) {
			if (data [models [i]]) {
				continue;
			}
			progress ({label: "Загрузка: " + store.getModel (models [i]).name});

			data [models [i]] = await store.getRecords ({
				model: models [i],
				filters: [["emp", "in", emps]]
			});
		}
		progress ({label: "Создание отчета"});

		let row = models.map (m => {
			let model = store.getModel (m);
			return {text: i18n (model.name), style: "border_bold", colSpan: modelPropertyNum [m]};
		});
		if (selected.experts || selected.expertsFIO) {
			let colSpan = (selected.experts ? 1 : 0) + (selected.expertsFIO ? 1 : 0);
			row.push ({text: "Эксперт 1", style: "border_bold", colSpan});
			row.push ({text: "Эксперт 2", style: "border_bold", colSpan});
			row.push ({text: "Эксперт 3", style: "border_bold", colSpan});
		}
		let rows = [
			row
		];
		row = [];
		columns.forEach (c => {
			if (selected [c.id]) {
				row.push ({text: c.label, style: "border_bold"});
			}
		});
		if (selected.experts || selected.expertsFIO) {
			if (selected.experts) {
				row.push ({text: "Статус", style: "border_bold"});
			}
			if (selected.expertsFIO) {
				row.push ({text: "Ф.И.О.", style: "border_bold"});
			}
			if (selected.experts) {
				row.push ({text: "Статус", style: "border_bold"});
			}
			if (selected.expertsFIO) {
				row.push ({text: "Ф.И.О.", style: "border_bold"});
			}
			if (selected.experts) {
				row.push ({text: "Статус", style: "border_bold"});
			}
			if (selected.expertsFIO) {
				row.push ({text: "Ф.И.О.", style: "border_bold"});
			}
		}
		rows.push (row);

		for (let i = 0; i < data.declaration.length; i ++) {
			let declarationRecord = data.declaration [i];
			let emp = declarationRecord.emp, n = 0;

			while (1) {
				let hasData = false;
				let row = [];

				// eslint-disable-next-line
				for (let j = 0; j < columns.length; j ++) {
					let c = columns [j];

					if (!selected [c.id]) {
						continue;
					}
					let records = c.model == "declaration" ? [declarationRecord] :
						data [c.model].filter (record => record [c.model == "emp" ? "id" : "emp"] == emp)
					;
					let record = records [n];
					let text = "";

					if (record) {
						hasData = true;

						if (c.property == "fio") {
							text = `${record.surname || ""} ${record.forename || ""} ${record.patronymic || ""}`;
						} else {
							text = record [c.property];
							let property = store.getModel (c.model).properties [c.property];

							if (text && property) {
								if (property?.type == 3) {
									text = getTimestampString (text, {hideSeconds: true});
								}
								if (property?.type >= 1000) {
									await store.getDict (property?.type);
									text = store.dict [property?.type][text]?.getLabel ();
								}
							}
						}
					}
					row.push ({text, style: "border"});
				}
				if (selected.experts || selected.expertsFIO) {
					let records = expertMap [declarationRecord.id] || [];

					for (let i = 0; i < 3; i ++) {
						let record = records [i];
						let text = "Не назначен", fio = "";

						if (record) {
							if (record.state) {
								text = store.dict ["d.declaration.expertState"][record.state].name;
							} else {
								text = "Рассматривается";
							}
							fio = record.fio;
						}
						if (selected.experts) {
							row.push ({text, style: "border"});
						}
						if (selected.expertsFIO) {
							row.push ({text: fio, style: "border"});
						}
					}
				}
				if (hasData) {
					rows.push (row);
				} else {
					break;
				}
				n ++;
			}
		}
		Object.assign (reportStyles, {
			border_bold: {
				font: {name: "Arial", size: 10, bold: true},
				border: {
					top: {style: "thin"},
					left: {style: "thin"},
					bottom: {style: "thin"},
					right: {style: "thin"}
				},
				alignment: {
					vertical: "top",
					horizontal: "left",
					wrapText: true
				}
			}
		});
		let reportColumns = Object.keys (selected).map (() => 20);

		if (selected.experts) {
			reportColumns = [...reportColumns, 20, 20, 20, 20, 20, 20];
		}
		createReport ({
			rows,
			columns: reportColumns,
			font: {
				name: "Arial",
				size: 10
			},
			worksheetOpts: {
				pageSetup: {
					margins: {
						left: 0.4, right: 0.4,
						top: 0.4, bottom: 0.4,
						header: 0.1, footer: 0.1
					},
					orientation: "landscape"
				}
			}
		});
	}

	let prevModel, items = [], key = 0;

	columns.forEach (c => {
		let model = store.getModel (c.model);
		let property = model.properties [c.property];

		c.label = c.label || property?.name || c.property;
		c.id = `${c.model}-${c.property}`;

		if (prevModel != c.model) {
			prevModel = c.model;
			items.push (<div key={key ++}><h5>{i18n (model.name)}</h5></div>);
		}
		items.push (<BooleanField key={key ++} label={c.label} value={selected[c.id]} onChange={({value}) => {
			let o = Object.assign ({}, selected);

			if (value) {
				o [c.id] = true;
			} else {
				delete o [c.id];
			}
			setSelected (o);
		}} />);
	});
	items.push (<div key={key ++}><h5>Эксперты</h5></div>);
	items.push (<BooleanField key={key ++} label="Статус" onChange={({value}) => {
		let o = Object.assign ({}, selected);

		if (value) {
			o.experts = true;
		} else {
			delete o.experts;
		}
		setSelected (o);
	}} />);
	items.push (<BooleanField key={key ++} label="Ф.И.О." onChange={({value}) => {
		let o = Object.assign ({}, selected);

		if (value) {
			o.expertsFIO = true;
		} else {
			delete o.expertsFIO;
		}
		setSelected (o);
	}} />);

	return <div>
		{items}
		<Action icon="fas fa-print" label="Создать отчет" onClick={build} disabled={!Object.keys (selected).length} />
	</div>;
}

export default function Declarations (props) {
	let [query, setQuery] = useState ("declaration.list");
	let [params, setParams] = useState ({id: "is not null", archivePlace: props.archivePlace});
	let [filters, setFilters] = useState ([]);
	let [loading, setLoading] = useState (true);
	let [refresh, setRefresh] = useState (false);
	let [declarationRecords, setDeclarationRecords] = useState ([]);
	let [listVisible, setListVisible] = useState (true);
	let [expertMap, setExpertMap] = useState ([]);
	let [useArchive, setUseArchive] = useState(false)
	let [archiveRecs, setArchiveRecs] = useState([])
	let gridEl = useRef (null);

	function updateDeclarationRecords (records) {
		let m = {}, filtered = [];

		for (let i = 0; i < records.length; i ++) {
			if (!m [records [i].id]) {
				filtered.push (records [i]);
				m [records [i].id] = true;
			}
		}
		setDeclarationRecords (filtered);
	}
	useEffect (() => {
		async function load () {
			await store.getDict ("d.exam.type");
			await store.getDict ("d.terr");
			await store.getDict ("d.declaration.expertState");
			let expertMap = {}, expertRecords = await store.getRecords ({model: "t.declaration.emp"});
			let empRecords = expertRecords.length ? (await store.getRecords ({
				model: "emp",
				filters: [
					["id", "in", expertRecords.map (record => record.emp)]
				]
			})) : [];
			expertRecords.forEach (record => {
				expertMap [record.declaration] = expertMap [record.declaration] || [];
				expertMap [record.declaration].push (record);

				let empRecord = empRecords.find (empRecord => empRecord.id == record.emp);
				record.fio = empRecord ? `${empRecord.surname || ""} ${empRecord.forename || ""} ${empRecord.patronymic || ""}` : "";
			});
			setExpertMap (expertMap);

			if (store.roleCode == "pedmod") {
				setQuery ("declaration.pedmod");
				setFilters ([["post", "=", ""]])
			}
			if (store.roleCode == "pedObserver") {
				if (params.archivePlace === '=1') {
					setQuery ("declaration.pedmod");
				} else {
					setQuery ("declaration.pedobserver");
				}
				setFilters ([["post", "=", ""]])
			}
			if (store.roleCode == "chiefmod") {
				setQuery ("declaration.chiefmod");
				setFilters ([["state", "=", ""]])
			}
			if (store.roleCode == "terrmod") {
				let terr = (await store.getRecord (mediator.record.user.emp)).terr;
				setQuery ("declaration.terrmod");
				setParams ({id: "is not null", archivePlace: props.archivePlace, terr});
			}
			if (store.roleCode == "observer") {
				let terr = (await store.getRecord (mediator.record.user.emp)).terr;

				if (terr) {
					setQuery ("declaration.terrmod");
					setParams ({id: "is not null", archivePlace: props.archivePlace, terr});
				} else {
					setQuery ("declaration.chiefmod");
				}
			}
			setLoading (false);
		}
		load ();
		// eslint-disable-next-line
	}, []);

	if (loading) {
		return <Loading />;
	}
	async function onRemove ({id}) {
		let result;
		let expertRecs = await store.getRecs ({
			model: "t.declaration.emp",
			filters: [
				["declaration", "=", id]
			]
		});
		if (expertRecs.length) {
			throw new Error ("В заявлении назначены эксперты");
		}
		setDeclarationRecords ([]);
		result = await store.remote ({model: "declaration", id, method: "sendRemoveNotice"});
		await store.removeRecord (id);

		setRefresh (!refresh);
		return result;
	}
	function onRenderCell ({cell, col, rec}) {
		if (["expert1", "expert2", "expert3", "expert1fio", "expert2fio", "expert3fio"].indexOf (col.code) > -1 ) {
			let records = expertMap [rec.id] || [];
			let record = records [col.code [6] - 1];
			let text = "Не назначен";

			if (record) {
				if (["expert1", "expert2", "expert3"].indexOf (col.code) > -1 ) {
					if (record.state) {
						text = store.dict ["d.declaration.expertState"][record.state].name;
					} else {
						text = "Рассматривается";
					}
				} else {
					text = record.fio;
				}
			}
			return <span>{text}</span>;
		} else {
			return cell;
		}
	}
	function onTableRow ({row, rec}) {
		let props = {};

		if (!row.props.className) {
			let records = expertMap [rec.id] || [];

			if (records.length == 3) {
				let accepted = 0, rejected = 0;
				for (let i = 0; i < records.length; i ++) {
					if (records [i].state == store.dict ["d.declaration.expertState"]["accepted"].id) {
						accepted ++;
					}
					if (records [i].state == store.dict ["d.declaration.expertState"]["rejected"].id) {
						rejected ++;
					}
				}
				if (accepted == 3) {
					props.className = "table-success";
				}
				if (rejected == 3) {
					props.className = "table-danger";
				}
				if (accepted + rejected == 3 && accepted < 3) {
					props.className = "table-warning";
				}
			}
		}
		if (rec.style) {
			let style = JSON.parse (rec.style);
			return React.cloneElement (row, {style: {backgroundColor: style.color}});
		} else {
			return React.cloneElement (row, props);
		}
	}
	async function expertReport ({id}) {
		let record = await store.getRecord (id);
		await record.expertReport ();
	}

	async function onDeclarationFilters(filters) {
		if (store.roleCode != 'pedmod') {
			return
		}
		filters = filters.map(f => [f.col, f.oper, f.value])

		let recs = await store.getRecs ({
			query: "declaration.pedmod",
			filters,
			archivePlace: '=1',
			id: 'is not null',
			offset: 0,
			limit: 30
		})
		setArchiveRecs(recs)
	}
	function MessageStyle ({recordId, onClose, grid}) {
		let [record, setRecord] = useState (null)
		let [label, setLabel] = useState (null)
		let [color, setColor] = useState (null)

		useEffect (() => {
			async function load () {
				let record = await store.getRecord (recordId)
				setRecord (record)
				setLabel (record.label)
				let style = JSON.parse (record.style || "{}")
				setColor (style.color)
			}
			load ();
			// eslint-disable-next-line
		}, []);

		if (!record) {
			return <Loading />;
		}
		return <div>
			<StringField label="Метка чата" value={label} onChange={({value}) => setLabel (value)} />
			<ColorField value={color} onChange={({value}) => setColor (value)} colors={['#BB8FCE', '#F7DC6F', '#D98880', '#7FB3D5', '#DAF7A6']} />
			<Action store={store} label="Ok" icon="fas fa-check" transaction onClick={async () => {
				record.label = label;
				let style = JSON.parse (record.style || "{}");
				style.color = color;
				record.style = JSON.stringify (style);
				await record.sync ();
				onClose ();
				grid.setState ({refresh: !grid.state.refresh});
			}} />
		</div>;
	}
	let selectedStateId = null

	try {
		let hashString = decodeURI (window.location.hash.substr (1) || "{}")
		let hashObject = JSON.parse (hashString)
		selectedStateId = hashObject.declarations?.filters[0][2]
	} catch(error) {
	}

	return <div>
		<div className="d-flex align-items-center mb-2">
			<PageTitle>{props.archivePlace == "is null" ? "Заявления" : (props.archivePlace == "=1" ? "Архив" : "Архив КТ")}</PageTitle>
			{declarationRecords.length ? <Action
				className="ml-2"
				icon={listVisible ? "fas fa-chevron-left" : "fas fa-chevron-right"}
				title={listVisible ? "Показать заявление" : "Показать список заявлений"}
				onClick={() => setListVisible (!listVisible)}
			/> : null}
		</div>
		<div className="row no-gutters">
			<div className={listVisible ? (declarationRecords.length ? "col-11" : "col-12") : "col-1"}>
				<div className="border rounded p-1 mr-2">
					<div className={`div-visible ${listVisible ? "" : "overflow-hidden div-darker"}`}>
						<Grid {...props} id="declarations" store={store} query={query} params={params}
							  refresh={refresh} onRenderCell={onRenderCell} onTableRow={onTableRow} ref={gridEl}
							  filters={filters} onFilters={onDeclarationFilters}
						>
							<Action icon="fas fa-print" className="mb-1" label="Отчет" modalComponent={Report} gridEl={gridEl} expertMap={expertMap} />
							<Action icon={store.roleCode == "pedObserver" ? "fas fa-eye" : "fas fa-edit"} label="Открыть" onClick={async ({id}) => {
								setListVisible (false);
								let declarationRecord = await store.getRecord (id);

								if (declarationRecords.length > 1) {
									return updateDeclarationRecords ([declarationRecord, ...declarationRecords]);
								} else {
									setDeclarationRecords ([declarationRecord]);
								}
							}} selected />
							{store.roleCode == "pedObserver" ? null : <Action icon="fas fa-plus" label="Открыть в новой вкладке" onClick={async ({id}) => {
								setListVisible (false);
								let declarationRecord = await store.getRecord (id);
								return updateDeclarationRecords ([...declarationRecords, declarationRecord]);
							}} selected />}

							{(["terrmod", "observer", "pedObserver"].indexOf (store.roleCode) == -1 && props.archivePlace == "is null") ? <Action icon="fas fa-minus" label="Удалить" onClick={onRemove} confirm selected transaction /> : null}
							{["chiefmod", "terrmod"].indexOf (store.roleCode) > -1 ? <div className={"d-flex"}>
								{[{
									code: "await", name: "Поданные"
								}, {
									code: "editing", name: "Редактируется"
								}, {
									code: "match", name: "Актуальные"
								}, {
									code: "commission", name: "Направлен на комиссию"
								}, {
									code: "chief", name: "Руководители ОО"
								}, {
									code: "certificationFailed", name: "Аттестация не состоялась"
								}, {
									code: "reserve", name: "Кадровый резерв"
								}].map ((o, j) => {
									const stateId = store.dict ["d.declaration.state"][o.code].id

									return <Link
										key={`d-${j}`} className={`btn ${stateId == selectedStateId ? "bg-selected-button" : "btn-primary"} mr-1 mb-1`}
										to={encodeURI (`/declarations#{"declarations":{"showFilters":true,"dockFilters":"top","filters":[["state","=",${stateId}]],"selected":null}}`)}
										onClick={() => setTimeout (() => window.dispatchEvent (new HashChangeEvent ("hashchange")), 200)}
									>{o.name}</Link>
								})}
							</div> : null}
							{store.roleCode == "chiefmod" ? <Action label="Отчет" icon="fas fa-print" selected onClick={async ({id}) => {
								let record = await store.getRecord (id);
								await record.chiefReport ();
							}} /> : null}
							{["pedmod", "admin"].indexOf (store.roleCode) > -1 ? <Action icon="fas fa-print" label="Экспертное заключение" onClick={expertReport} selected /> : null}
							{["chiefmod", "admin"].indexOf (store.roleCode) > -1 ? <Action label="Метка" icon="fas fa-tags" selected popupComponent={MessageStyle}/> : null}
						</Grid>
						{(store.roleCode == 'pedmod' && props.archivePlace == "is null") ? <div className="my-2">
							<BooleanField label="Учитывать архив" value={useArchive} onChange={({value}) => setUseArchive (value)} />
							{useArchive ? <div>
								<h5>Архив</h5>
								<table className="table table-sm table-bordered bg-empinfo">
									<thead>
									<tr>
										<th>id</th>
										<th>Ф.И.О.</th>
										<th>Подано на категорию</th>
										<th>Статус</th>
										<th>Дата</th>
										<th>Текущее место работы</th>
										<th>Должность</th>
										<th>Территория</th>
										<th>Логин</th>
										<th>Электронная почта</th>
									</tr>
									</thead>
									<tbody>
									{archiveRecs.map((rec, i) => {
										return <tr key={i}>
											<td>{rec.id}</td>
											<td>{rec.fio}</td>
											<td>{store.dict['d.emp.category'][rec.category].name}</td>
											<td>{store.dict['d.declaration.state'][rec.state].name}</td>
											<td>{rec.date.toLocaleDateString ()}</td>
											<td>{rec.orgName}</td>
											<td>{rec.post ? store.dict['d.emp.post'][rec.post].name : ''}</td>
											<td>{rec.terr ? store.dict['d.terr'][rec.terr].name : ''}</td>
											<td>{rec.login}</td>
											<td>{rec.email}</td>
										</tr>
									})}
									</tbody>
								</table>
							</div> : null}
						</div> : null}
					</div>
				</div>
			</div>
			{declarationRecords.length ? <div className={listVisible ? "col-1" : "col-11"}>
				<div className="border rounded p-1">
					<div className={`div-visible ${listVisible ? "overflow-hidden div-darker" : ""}`}>
						<Tabs id="mainTabs" closable closeButtonClass="btn btn-link text-white my-0 ml-2 p-0" onClose={tab => {
							let records = [...declarationRecords];
							records.splice (tab, 1);
							setDeclarationRecords (records);

							if (!records.length) {
								setListVisible (true);
							}
						}}>
							{declarationRecords.map ((record, i) => {
								return <Tab key={i} label={`Заявление: ${record.id}`}>
									{record.chief ?
										<ChiefDeclaration {...props} store={store} id={record.id} /> :
										<PedagogDeclaration {...props} store={store} id={record.id} />}
								</Tab>;
							})}
						</Tabs>
					</div>
				</div>
			</div> : null}
		</div>
	</div>;
};
