/* eslint-disable no-whitespace-before-property */
/* eslint-disable eqeqeq */

import {useState, useReducer, useEffect, createRef} from "react";
import {Field, Action, Form, goRidLocation, Loading, Tabs, Tab, JsonField, StringField, RadioField} from "objectum-react";
import mediator, {store, getJulianDay} from "../modules/mediator";
import data from "../modules/data";

function init ({rid}) {
	return {
		loading: true,
		rid,
		emp: null,
		orgRecords: [],
		eduRecords: [],
		trainingRecords: [],
		retrainingRecords: [],
		rewardRecords: [],
		crimeRecords: [],
		languageRecords: [],
		electionRecords: [],
		jobRecords: [],
		expRecords: [],
		otherCitizenshipVisible: false,
		eduCreationVisible: false,
		rewardCreationVisible: false,
		retrainingCreationVisible: false,
		crimeCreationVisible: false,
		languageCreationVisible: false,
		electionCreationVisible: false,
		jobCreationVisible: false,
		expCreationVisible: false,
		scienceVisible: true
	};
}

function reducer (state, action) {
	return Object.assign ({}, state, action);
}

export default function Chief (props) {
	let [state, dispatch] = useReducer (reducer, props, init);
	let [formRefs, setFormRefs] = useState ({});
	let allRecords = [
		...state.eduRecords,
		...state.trainingRecords,
		...state.retrainingRecords,
		...state.rewardRecords,
		...state.crimeRecords,
		...state.languageRecords,
		...state.electionRecords,
		...state.jobRecords,
		...state.expRecords
	];
	useEffect (() => {
		let refs = {
			"emp-1": formRefs ["emp-1"] || createRef (),
			"emp-3": formRefs ["emp-3"] || createRef (),
			"emp-4": formRefs ["emp-4"] || createRef (),
			"emp-5": formRefs ["emp-5"] || createRef (),
			"emp-6": formRefs ["emp-6"] || createRef (),
			"user": formRefs ["user"] || createRef (),
			"job-new": formRefs ["job-new"] || createRef ()
		};
		allRecords.forEach (record => refs [record.id] = formRefs [record.id] || createRef ())
		setFormRefs (refs);
		// eslint-disable-next-line
	}, [allRecords.length]);

	useEffect (() => {
		async function load () {
			let action = {loading: false};
			let [orgRecords, stateRecords, hasDeclaration] = await Promise.all ([
				await store.getRecords ({model: "org"}),
				await store.getDict ("d.declaration.state"),
				!(await mediator.canUserEdit ()) || store.roleCode == "terrmod" || store.roleCode == "observer",
				await store.getDict ("d.level"),
				await store.getDict ("d.contest.type"),
				await store.getDict ("d.emp.reward"),
				await store.getDict ("d.declaration.nomination")
			]);
			Object.assign (action, {orgRecords, stateRecords, hasDeclaration});

			if (state.rid) {
				let userRecord = await store.getRecord (state.rid);

				if (userRecord.emp) {
					action.emp = userRecord.emp;
					action.loading = true;
				}
			}
			action.isAdmin = store.username == "admin" || ["pedmod", "chiefmod", "admin"].indexOf (store.roleCode) > -1;
			dispatch (action);
		};
		load ();
	}, [state.rid]);

	useEffect (() => {
		async function load () {
			let action = {};

			if (state.emp) {
				action.loading = false;
				let [
					empRecord,
					declarationEmpRecords
				] = await Promise.all ([
					await store.getRecord (state.emp),
					await store.getRecords ({
						model: "t.declaration.emp",
						filters: [
							["emp", "=", state.emp]
						]
					})
				]);
				let {
					eduRecords, trainingRecords, retrainingRecords, rewardRecords, crimeRecords, languageRecords, electionRecords, jobRecords, expRecords
				} = await empRecord.loadChiefData ();

				Object.assign (action, {
					eduRecords,
					trainingRecords,
					retrainingRecords,
					rewardRecords,
					crimeRecords,
					languageRecords,
					electionRecords,
					jobRecords,
					expRecords
				});
				action.empName = `${empRecord.surname} ${empRecord.forename} ${empRecord.patronymic || ""}`;
				action.noJob = empRecord.noJob;
				action.noCrime = empRecord.noCrime;
				action.noElection = empRecord.noElection;
				action.nomination = empRecord.nomination;
				action.postStrVisible = !!empRecord.postStr;
				action.orgStrVisible = !!empRecord.orgStr;

/*
				if (empRecord.scienceDegree || empRecord.scienceRank) {
					action.scienceVisible = true;
				}
*/
				if (empRecord.citizenship) {
					let record = await store.getRecord (empRecord.citizenship);
					if (record.code == "other") {
						action.otherCitizenshipVisible = true;
					}
				}
				if (empRecord.org) {
					let orgRecord = await store.getRecord (empRecord.org);
					action.orgName = orgRecord.name;
				}
				if (empRecord.orgStr) {
					action.orgName = empRecord.orgStr;
				}
				if (empRecord.terr) {
					let terrRecord = await store.getRecord (empRecord.terr);
					action.terr = empRecord.terr;
					action.terrName = terrRecord.name;
				}
				if (empRecord.post) {
					let postRecord = await store.getRecord (empRecord.post);
					action.postName = postRecord.name;
				}
				if (empRecord.postStr) {
					action.postName = empRecord.postStr;
				}
				action.declarations = declarationEmpRecords.map (record => record.declaration);
				let declarationRecords = await store.getRecords ({
					model: "declaration",
					filters: [
						["id", "in", action.declarations]
					]
				});
				action.activeDeclarations = declarationRecords.filter (record => [
					store.dict ["d.declaration.state"]["await"].id,
					store.dict ["d.declaration.state"]["processing"].id,
					store.dict ["d.declaration.state"]["expired"].id
				].indexOf (record.state) > -1).map (record => record.id);
				action.closedDeclarations = declarationRecords.filter (record => [
					store.dict ["d.declaration.state"]["assigned"].id,
					store.dict ["d.declaration.state"]["rejected"].id
				].indexOf (record.state) > -1).map (record => record.id);

				rewardRecords.forEach (record => action [`rewardLevel-${record.id}`] = record.level);
				jobRecords.forEach (record => action [`untilNow-${record.id}`] = record.untilNow);
				Object.assign (action, calcJob (action.jobRecords));
			}
			dispatch (action);
		};
		load ();
	}, [state.emp]);

	async function onCreate (rid) {
		dispatch ({rid});
		props.onCreate (rid);
		goRidLocation (props, rid);
	}

	async function onCreateEmp (emp) {
		try {
			await store.startTransaction ();
			let record = await store.getRecord (state.rid);
			record.emp = emp;
			await record.sync ();
			await store.commitTransaction ();
		} catch (err) {
			await store.rollbackTransaction ();
			throw err;
		}
		dispatch ({emp});
	}

	function calcJob (records) {
		let action = {jobNum: 0, jobDays: 0};

		records.forEach (record => {
			if (record.startDate && record.endDate && record.endDate > record.startDate) {
				action [`jobDays-${record.id}`] = getJulianDay (record.endDate) - getJulianDay (record.startDate);
				action.jobNum ++;
				action.jobDays += action [`jobDays-${record.id}`];
			}
		});
		return action;
	};
	async function load (model) {
		let records = await store.getRecords ({model, filters: [["emp", "=", state.emp]]});
		let action = {};

		if (model == "t.emp.edu") {
			action.eduRecords  = records;
			action.eduCreationVisible = false;
		}
		if (model == "t.emp.training") {
			action.trainingRecords = records;
			action.trainingCreationVisible = false;
		}
		if (model == "t.emp.retraining") {
			action.retrainingRecords = records;
			action.retrainingCreationVisible = false;
		}
		if (model == "t.emp.reward") {
			action.rewardRecords = records;
			action.rewardCreationVisible = false;
		}
		if (model == "t.emp.crime") {
			action.crimeRecords = records;
			action.crimeCreationVisible = false;
		}
		if (model == "t.emp.language") {
			action.languageRecords = records;
			action.languageCreationVisible = false;
		}
		if (model == "t.emp.election") {
			action.electionRecords = records;
			action.electionCreationVisible = false;
		}
		if (model == "t.emp.job") {
			action.jobRecords = records;
			action.jobCreationVisible = false;
			Object.assign (action, calcJob (action.jobRecords));
		}
		if (model == "t.emp.exp") {
			action.expRecords = records;
			action.expCreationVisible = false;
		}
		dispatch (action);
	}
	async function removeRecord (id, model) {
		await store.startTransaction ();

		try {
			await store.removeRecord (id);
			await store.commitTransaction ();
			await load (model);
		} catch (err) {
			await store.rollbackTransaction ();
		}
	}
	function filterDict (d, codes) {
		return store.map.dict [d].filter (record => codes.indexOf (record.code) > -1);
	}

	function renderEdu (id) {
		return <div key={id} className={`border border-secondary p-1 mb-2 ${id ? "" : "bg-new"}`}>
			<Form
				ref={formRefs [id]} store={store} rsc="record" rid={id || null} mid="t.emp.edu" className="border mb-1" hideSaveButton
				hideLogButton={hideLogButton} defaults={{emp: state.emp}} onCreate={async () => await load ("t.emp.edu")}
				disabled={!!state.hasDeclaration}
				hideButtons={store.roleCode == "observer"}
			>
				<div className="row">
					<div className="col-4">
						<Field property="orgStr" label="Учебное заведение" values={data.org} />
					</div>
					<div className="col-4">
						<Field property="qualificationStr" />
					</div>
					<div className="col-4">
						<Field property="endYear" notNull />
					</div>
				</div>
				<div className="row">
					<div className="col-4">
						<Field property="faculty" notNull />
					</div>
					<div className="col-4">
						<Field property="direction" notNull />
					</div>
					<div className="col-4">
						<Field property="file" notNull description="Прикрепите файл не более 25 мб" maxSize={25 * 1024 * 1024} />
					</div>
				</div>
			</Form>
			{id && !state.hasDeclaration && <div className="text-right">
				<Action label="Удалить" icon="fas fa-minus-circle" confirm onClick={() => removeRecord (id, "t.emp.edu")} />
			</div>}
		</div>;
	}
	function renderRetraining (id) {
		return <div key={id} className={`border border-secondary p-1 mb-2 ${id ? "" : "bg-new"}`}>
			<Form
				ref={formRefs [id]} store={store} rsc="record" rid={id || null} mid="t.emp.retraining"
				className="border mb-1" hideLogButton={hideLogButton} defaults={{emp: state.emp}} hideSaveButton
				onCreate={async () => await load ("t.emp.retraining")} disabled={!!state.hasDeclaration}
				hideButtons={store.roleCode == "observer"}
			>
				<div className="row">
					<div className="col-8">
						<Field property="name" notNull />
					</div>
					<div className="col-4">
						<Field property="hours" notNull />
					</div>
				</div>
				<div className="row">
					<div className="col-4">
						<Field property="startDate" notNull />
					</div>
					<div className="col-4">
						<Field property="endDate" notNull />
					</div>
					<div className="col-4">
						<Field property="place" notNull />
					</div>
				</div>
				<div className="row">
					<div className="col-4">
						<Field property="num" notNull />
					</div>
					<div className="col-4">
						<Field property="regNum" notNull />
					</div>
					<div className="col-4">
						<Field property="file" notNull />
					</div>
				</div>
			</Form>
			{id && !state.hasDeclaration && <div className="text-right">
				<Action label="Удалить" icon="fas fa-minus-circle" confirm onClick={() => removeRecord (id, "t.emp.retraining")} />
			</div>}
		</div>;
	}
	function renderReward (id) {
		return <div key={id} className={`border border-secondary p-1 mb-2 ${id ? "" : "bg-new"}`}>
			<Form
				ref={formRefs [id]} store={store} rsc="record" rid={id || null} mid="t.emp.reward"
				className="border mb-1" hideLogButton={hideLogButton} defaults={{emp: state.emp}}
				onCreate={async () => await load ("t.emp.reward")} disabled={!!state.hasDeclaration} hideSaveButton
				hideButtons={store.roleCode == "observer"}
			>
				<div className="row">
					<div className="col-3">
						<Field property="level" dict records={filterDict ("d.level", ["region", "fed"])} onChange={({value}) => dispatch ({[`rewardLevel-${id}`]: value})} />
					</div>
					<div className="col-9">
						<Field property="reward" dict records={store.map.dict ["d.emp.reward"].filter (record => record.level == state [`rewardLevel-${id}`])} disabled={!state [`rewardLevel-${id}`]} />
					</div>
				</div>
				<div className="row">
					<div className="col-6">
						<Field property="url" notNull />
					</div>
					<div className="col-6">
						<Field property="file" notNull description="Прикрепите файл не более 25 мб" maxSize={25 * 1024 * 1024} />
					</div>
				</div>
			</Form>
			{id && !state.hasDeclaration && <div className="text-right">
				<Action label="Удалить" icon="fas fa-minus-circle" confirm onClick={() => removeRecord (id, "t.emp.reward")} />
			</div>}
		</div>;
	}
	function renderTraining (id) {
		return <div key={id} className={`border border-secondary p-1 mb-2 ${id ? "" : "bg-new"}`}>
			<Form
				ref={formRefs [id]} store={store} rsc="record" rid={id || null} mid="t.emp.training"
				className="border mb-1" hideLogButton={hideLogButton} defaults={{emp: state.emp}}
				onCreate={async () => await load ("t.emp.training")} disabled={!!state.hasDeclaration}
				hideButtons={store.roleCode == "observer"} hideSaveButton
			>
				<div className="row">
					<div className="col-8">
						<Field property="name" notNull />
					</div>
					<div className="col-2">
						<Field property="startDate" min={new Date ().getFullYear () - 3} notNull />
					</div>
					<div className="col-2">
						<Field property="endDate" min={new Date ().getFullYear () - 3} notNull />
					</div>
				</div>
				<div className="row">
					<div className="col-4">
						<Field property="docNum" notNull />
					</div>
					<div className="col-4">
						<Field property="regNum" notNull />
					</div>
					<div className="col-4">
						<Field property="hours" notNull />
					</div>
				</div>
				<div className="row">
					<div className="col-6">
						<Field property="place" notNull />
					</div>
					<div className="col-6">
						<Field property="file" notNull description="Прикрепите файл не более 25 мб" maxSize={25 * 1024 * 1024} />
					</div>
				</div>
			</Form>
			{id && !state.hasDeclaration && <div className="text-right">
				<Action label="Удалить" icon="fas fa-minus-circle" confirm onClick={() => removeRecord (id, "t.emp.training")} />
			</div>}
		</div>;
	}
	function renderCrime (id) {
		return <div key={id} className={`border border-secondary p-1 mb-2 ${id ? "" : "bg-new"}`}>
			<Form
				ref={formRefs [id]} store={store} rsc="record" rid={id || null} mid="t.emp.crime"
				className="border mb-1" hideLogButton={hideLogButton} defaults={{emp: state.emp}}
				onCreate={async () => await load ("t.emp.crime")} disabled={!!state.hasDeclaration}
				hideButtons={store.roleCode == "observer"} hideSaveButton
			>
				<div className="row">
					<div className="col-6">
						<Field property="docType" label="Вид ответственности" />
					</div>
					<div className="col-6">
						<Field property="file" notNull accept=".pdf" />
					</div>
				</div>
				<Field property="comment" notNull />
			</Form>
			{id && !state.hasDeclaration && <div className="text-right">
				<Action label="Удалить" icon="fas fa-minus-circle" confirm onClick={() => removeRecord (id, "t.emp.crime")} />
			</div>}
		</div>;
	}
	function renderLanguage (id) {
		return <div key={id} className={`border border-secondary p-1 mb-2 ${id ? "" : "bg-new"}`}>
			<Form
				ref={formRefs [id]} store={store} rsc="record" rid={id || null} mid="t.emp.language"
				className="border mb-1" hideLogButton={hideLogButton} defaults={{emp: state.emp}}
				onCreate={async () => await load ("t.emp.language")} disabled={!!state.hasDeclaration}
				hideButtons={store.roleCode == "observer"} hideSaveButton
			>
				<div className="row">
					<div className="col-6">
						<Field property="language" dict />
					</div>
					<div className="col-6">
						<Field property="languageLevel" dict />
					</div>
				</div>
			</Form>
			{id && !state.hasDeclaration && <div className="text-right">
				<Action label="Удалить" icon="fas fa-minus-circle" confirm onClick={() => removeRecord (id, "t.emp.language")} />
			</div>}
		</div>;
	}
	function renderElection (id) {
		return <div key={id} className={`border border-secondary p-1 mb-2 ${id ? "" : "bg-new"}`}>
			<Form
				ref={formRefs [id]} store={store} rsc="record" rid={id || null} mid="t.emp.election"
				className="border mb-1" hideLogButton={hideLogButton} defaults={{emp: state.emp}}
				onCreate={async () => await load ("t.emp.election")} disabled={!!state.hasDeclaration}
				hideButtons={store.roleCode == "observer"} hideSaveButton
			>
				<div className="row">
					<div className="col-6">
						<Field property="docType" />
					</div>
					<div className="col-6">
						<Field property="file" notNull />
					</div>
				</div>
			</Form>
			{id && !state.hasDeclaration && <div className="text-right">
				<Action label="Удалить" icon="fas fa-minus-circle" confirm onClick={() => removeRecord (id, "t.emp.election")} />
			</div>}
		</div>;
	}
	function renderJob (record) {
		let id = record?.id || null;
		let days = state [`jobDays-${id}`];

		return <div key={id} className={`border border-secondary p-1 mb-2 ${id ? "" : "bg-new"}`}>
			<Form
				ref={formRefs [id || 'job-new']} store={store} rsc="record" rid={id || null} mid="t.emp.job"
				className="border mb-1" hideLogButton={hideLogButton} defaults={{emp: state.emp}}
				onCreate={async (id) => {
					dispatch ({
						[`untilNow-${id}`]: state ["untilNow-null"],
						"untilNow-null": null
					})
					await load ("t.emp.job");
				}}
				disabled={!!state.hasDeclaration || (!id && !!state.noJob)}
				hideButtons={store.roleCode == "observer"} hideSaveButton
			>
				<div className="row">
					<div className="col-4">
						<Field property="place" notNull />
					</div>
					<div className="col-4">
						<Field property="postStr" notNull />
					</div>
					<div className="col-4">
						<Field property="reason" notNull={!state [`untilNow-${id}`]} disabled={!!state [`untilNow-${id}`]} />
					</div>
				</div>
				<div className="row">
					<div className="col-4">
						<Field property="startDate" notNull />
					</div>
					<div className="col-4">
						<Field property="endDate" notNull={!state [`untilNow-${id}`]} disabled={!!state [`untilNow-${id}`]} />
					</div>
					<div className="col-4">
						<Field property="untilNow" onChange={({value}) => {
							dispatch ({[`untilNow-${id}`]: value});
							formRefs [id || 'job-new'].current.setState({"endDate": "", "reason": ""})
						}} />
					</div>
				</div>
			</Form>
			{id && !state.hasDeclaration && <div className="d-flex justify-content-end align-items-center">
				<div className={"mr-2"}>{days ? <div className="">{`${days / 365 | 0} л. ${days % 365 / 30 | 0} м. ${days % 365 % 30 | 0} д.`}</div> : null}</div>
				<Action label="Удалить" icon="fas fa-minus-circle" confirm onClick={() => removeRecord (id, "t.emp.job")} />
			</div>}
		</div>;
	}
/*
	function renderExp (id) {
		return <div key={id} className={`border border-secondary p-1 mb-2 ${id ? "" : "bg-new"}`}>
			<Form ref={formRefs [id]} store={store} rsc="record" rid={id || null} mid="t.emp.exp" className="border mb-1" hideLogButton={hideLogButton} defaults={{emp: state.emp}} onCreate={async () => await load ("t.emp.exp")} disabled={!!state.hasDeclaration}>
				<div className="row">
					<div className="col-4">
						<Field property="place" />
					</div>
					<div className="col-4">
						<Field property="post" />
					</div>
					<div className="col-4">
						<Field property="reason" />
					</div>
				</div>
				<div className="row">
					<div className="col-4">
						<Field property="startDate" />
					</div>
					<div className="col-4">
						<Field property="endDate" />
					</div>
					<div className="col-4">
					</div>
				</div>
			</Form>
			{id && !state.hasDeclaration && <div className="text-right">
				<Action label="Удалить" icon="fas fa-minus-circle" confirm onClick={() => removeRecord (id, "t.emp.job")} />
			</div>}
		</div>;
	}
*/
	async function onCheck () {
		let userRecords = await store.getRecords ({
			model: "objectum.user",
			filters: [
				["snils", "=", state.snils]
			]
		});
		userRecords = userRecords.filter (record => record.id != state.rid);

		if (!userRecords.length) {
			return "Указанный СНИЛС не найден";
		}
		let userRecord = userRecords [0];

		if (!userRecord.emp) {
			return `Указанный СНИЛС найден. Пользователь id: ${userRecord.id}, роль: ${store.dict ["objectum.role"][userRecord.role].name}`;
		}
		let declarationRecords = await store.getRecords ({
			model: "declaration",
			filters: [
				["emp", "=", userRecord.emp]
			]
		});
		if (!declarationRecords.length) {
			return `Указанный СНИЛС найден. Пользователь id: ${userRecord.id}, роль: ${store.dict ["objectum.role"][userRecord.role].name}`;
		}
		let examRecords = await store.getRecords ({model: "exam"});

		return `Указанный СНИЛС найден. Пользователь id: ${userRecord.id}, роль: ${store.dict ["objectum.role"][userRecord.role].name}. Заявления: ${declarationRecords.map (record => {
			let examRecord = examRecords.find (examRecord => examRecord.id == (record.exam || record.chiefExam));
			return `id: ${record.id}, дата: ${record.date.toLocaleDateString ()}, экзамен: ${examRecord.date.toLocaleDateString ()}`;
		}).join ("; ")}`;
	}
	async function onSave ({progress}) {
		let forms = [], savingHasError = false;
		dispatch ({saving: true});

		for (let el of Object.values (formRefs)) {
			if (el.current && el.current.isChanged ()) {
				forms.push(el.current);
			}
		}
		for (let i = 0; i < forms.length; i ++) {
			progress ({value: i + 1, max: forms.length});
			let saveResult = await forms[i].onSave ();

			if (!saveResult) {
				savingHasError = true;
			}
		}
		dispatch ({saving: false, savingHasError});
	}
	function copyAddress() {
		const form = formRefs ["emp-1"].current
/*
		const fields = form.getFields(form.props.children)
		const value = fields['address'].getValue()

		fields['regAddress'].onChange({ target: { value }})
*/
		const value = form.state['address']
		form.setState({ regAddress: value })
	}
	if (state.loading) {
		return <Loading />;
	}
	let hideLogButton = ["admin", "pedmod", "chiefmod"].indexOf (store.roleCode) == -1;
	let untilNow = false

	for (let a in state) {
		if (a.startsWith('untilNow-') && state[a]) {
			untilNow = true
			break
		}
	}
	return <div>
{/*
		{state.empName && <table className="table table-sm bg-empinfo">
			<thead>
			<tr>
				<th>Ф.И.О.</th><th>Текущее место работы</th><th>Текущая должность</th><th>Территория</th>
			</tr>
			</thead>
			<tbody>
			<tr>
				<td>{state.empName}</td>
				<td>{state.orgName}</td>
				<td>{state.postName}</td>
				<td>{state.terrName}</td>
			</tr>
			</tbody>
		</table>}
*/}
		{!!state.hasDeclaration && <div className="alert alert-info my-2">{["terrmod", "observer"].indexOf (store.roleCode) > -1 ? "Редактирование недоступно" : "Редактирование недоступно т.к. вы подали заявление в текущем учебном году"}</div>}
		<Tabs>
			<Tab label="Общие сведения"><div className="p-1">
				<div className="border border-secondary mb-3 bg-empinfo p-1">
					<h5>Учетная запись пользователя</h5>
					<Form
						ref={formRefs ["user"]} store={store} rsc="record" rid={state.rid} mid="objectum.user"
						onCreate={onCreate} className="border mb-1" hideLogButton={hideLogButton} hideSaveButton
						onChange={({property, value}) => {
							if (property == "snils") {
								dispatch ({snils: value});
							}
						}}
						disabled={store.roleCode == "observer"}
						hideButtons={store.roleCode == "observer"}
					>
						<div className="row">
							<div className="col-4">
								<Field property="login" disabled={store.roleCode == "terrmod"} onChange={opts => opts.value = opts.value.trim ().toLowerCase ()} />
							</div>
							<div className="col-4">
								<Field property="password" />
							</div>
							<div className="col-4">
								<Field property="role" dict disabled={["pedagog", "chief", "expert", "manager"].indexOf (mediator.record.role?.code) > -1 || store.roleCode == "terrmod"} />
							</div>
						</div>
						<div className="row">
							<div className="col-4">
								<Field property="email" notNull disabled={store.roleCode == "terrmod"} />
							</div>
							<div className="col-4">
								<Field property="snils" regexp="/^[0-9]{3}-[0-9]{3}-[0-9]{3}-[0-9]{2}$/" exampleValue="111-111-111-11" onChange={opts => {
									let value = opts.value || "";
									let s = "";
									for (let i = 0; i < value.length; i ++) {
										if ("0123456789-".includes (value [i])) {
											if (value [i] == '-' && i !== 3 && i !== 7 && i !== 11) {
												continue;
											}
											s += value [i]
										}
									}
									if (s.length > 14) {
										s = s.substr (0, 14);
									}
									value = s;

									if (
										(
											/^[0-9]{3}$/.test (value) ||
											/^[0-9]{3}-[0-9]{3}$/.test (value) ||
											/^[0-9]{3}-[0-9]{3}-[0-9]{3}$/.test (value)
										) &&
										value.length >= (opts.oldValue || "").length
									) {
										value = value + "-";
									}
									opts.value = value;
								}} disabled={!!state.hasDeclaration} />
								{["terrmod", "chiefmod", "admin"].indexOf (store.roleCode) > -1 ? <Action label="Проверка" onClick={onCheck} disabled={!state.snils} /> : null}
							</div>
							<div className="col-4">
								<Field property="regDate" disabled={!state.isAdmin} />
							</div>
						</div>
					</Form>
				</div>
				{state.rid && <div>
					<div className="border p-2 mb-3">
						<Form
							ref={formRefs ["emp-1"]} store={store} rsc="record" rid={state.emp} mid="emp"
							onCreate={onCreateEmp} className="border mb-1" hideLogButton={hideLogButton}
							disabled={!!state.hasDeclaration}
							hideButtons={store.roleCode == "observer"} hideSaveButton
						>
							<div className="row">
								<div className="col-4">
									<Field property="surname" onChange={opts => opts.value = opts.value.trim()} />
								</div>
								<div className="col-4">
									<Field property="forename" onChange={opts => opts.value = opts.value.trim()} />
								</div>
								<div className="col-4">
									<Field property="patronymic" onChange={opts => opts.value = opts.value.trim()} />
								</div>
							</div>
							<div className="row">
								<div className="col-4">
									<Field property="sex" notNull />
								</div>
								<div className="col-4">
									<Field property="citizenship" notNull dict onChange={({value}) => {
										let otherCitizenshipVisible = false;

										if (value) {
											let record = store.map ["record"][value];
											if (record.code == "other") {
												otherCitizenshipVisible = true;
											}
										}
										dispatch ({otherCitizenshipVisible});
									}} />
								</div>
								<div className="col-4">
									{state.otherCitizenshipVisible ? <Field property="otherCitizenship" /> : null}
								</div>
							</div>
							<div className="row">
								<div className="col-4">
									<Field property="birthdate" />
								</div>
								<div className="col-4">
									<Field property="birthplace" />
								</div>
								<div className="col-4">
									<Field property="phone" />
								</div>
							</div>
							<Field property="address" label="Адрес проживания" notNull values={data.terr} />
							<Action label="Адрес регистрации совпадает с адресом проживания" btnClassName="btn btn-primary btn-labeled btn-sm mb-1" onClick={copyAddress} />
							<Field property="regAddress" values={data.terr} />

							<div className="row">
								<div className="col-6">
									<Field
										property="allegedOrg" dict
										disabled={!state.isAdmin}
										records={state.orgRecords.filter (record => record.accessible)} notNull
									/>
								</div>
								<div className="col-6">
									<Field property="allegedPost" notNull />
								</div>
							</div>
							{state.isAdmin ? <Field property="docsFile" label="Заявление в аттестационную комиссию" accept=".pdf" /> : null}
						</Form>
					</div>
					{(state.emp && store.roleCode != "observer") && <Action label={state.saving ? "Сохранение" : "Сохранить изменения"} disabled={state.saving || state.hasDeclaration} icon="fas fa-check" onClick={onSave} />}
					{state.savingHasError ? <div className="alert alert-danger my-1">Форма содержит ошибки</div> : null}
				</div>}
			</div></Tab>
			{state.emp && <Tab label="Профессиональная деятельность"><div className="p-1">
				<div className="mb-2 bg-empinfo p-1">
					<h6>Образование</h6>
					{state.eduRecords.map (record => renderEdu (record.id))}
					{!state.hasDeclaration && <div className="text-right mb-1">
						<Action label={state.eduCreationVisible ? "Закрыть" : "Открыть"} icon={state.eduCreationVisible ? "fas fa-angle-double-up" : "fas fa-angle-double-down"} onClick={() => dispatch ({eduCreationVisible: !state.eduCreationVisible})} />
					</div>}
					{state.eduCreationVisible && renderEdu ()}
				</div>
				<div className="mb-2 bg-empinfo p-1">
					<h6>Научная деятельность</h6>
					{state.scienceVisible ? <Form
						ref={formRefs ["emp-4"]} store={store} rsc="record" rid={state.emp} mid="emp" hideSaveButton
						className="border mb-1" hideLogButton={hideLogButton} disabled={!!state.hasDeclaration}
						onValidate={({values, errors}) => {
							if (values.scienceDegree && !values.degreeFile) {
								errors.degreeFile = "Пожалуйста прикрепите скан документа";
							}
							if (values.scienceRank && !values.rankFile) {
								errors.rankFile = "Пожалуйста прикрепите скан документа";
							}
						}}
						hideButtons={store.roleCode == "observer"}
					>
						<div className="border my-2 p-2">
							<div className="row">
								<div className="col-4">
									<Field property="scienceDegree" dict />
								</div>
								<div className="col-4">
									<Field property="degreeDate" />
								</div>
								<div className="col-4">
									<Field property="degreeCity" />
								</div>
							</div>
							<div className="row">
								<div className="col-4">
									<Field property="degreeRegNum" />
								</div>
								<div className="col-4">
									<Field property="degreeTheme" label="Тема работы" />
								</div>
								<div className="col-4">
									<Field property="degreeFile" />
								</div>
							</div>
						</div>
						<div className="border my-2 p-2">
							<div className="row">
								<div className="col-4">
									<Field property="scienceRank" dict />
								</div>
								<div className="col-4">
									<Field property="rankRegNum" />
								</div>
								<div className="col-4">
									<Field property="rankDate" />
								</div>
							</div>
							<div className="row">
								<div className="col-4">
									<Field property="rankTheme" label="Тема работы" />
								</div>
								<div className="col-8">
									<Field property="rankFile" />
								</div>
							</div>
						</div>
						<div className="border my-2 p-2">
							<JsonField property="articleUrl" label="Статьи или исследования" multi col props={[
								{prop: "article", label: "Название", col: 6, component: StringField},
								{prop: "url", label: "Ссылка", col: 6, component: StringField}
							]} />
						</div>
					</Form> : null}
{/*
					<div className="text-right mb-1">
						<Action label={state.scienceVisible ? "Закрыть" : "Открыть"} icon={state.scienceVisible ? "fas fa-angle-double-up" : "fas fa-angle-double-down"} onClick={() => dispatch ({scienceVisible: !state.scienceVisible})} />
					</div>
*/}
				</div>
				<div className="pb-2 mb-2 border-bottom bg-empinfo p-1">
					<h6>Повышение квалификации</h6>
					{state.trainingRecords.map (record => renderTraining (record.id))}
					{!state.hasDeclaration && <div className="text-right mb-1">
						<Action label={state.trainingCreationVisible ? "Закрыть" : "Открыть"} icon={`fas fa-angle-double-${state.trainingCreationVisible ? "up" : "down"}`} onClick={() => dispatch ({trainingCreationVisible: !state.trainingCreationVisible})} />
					</div>}
					{state.trainingCreationVisible && renderTraining ()}
				</div>
				<div className="mb-2 bg-empinfo p-1">
					<h6>Переподготовка</h6>
					{state.retrainingRecords.map (record => renderRetraining (record.id))}
					{!state.hasDeclaration && <div className="text-right mb-1">
						<Action label={state.retrainingCreationVisible ? "Закрыть" : "Открыть"} icon={`fas fa-angle-double-${state.retrainingCreationVisible ? "up" : "down"}`} onClick={() => dispatch ({retrainingCreationVisible: !state.retrainingCreationVisible})} />
					</div>}
					{state.retrainingCreationVisible && renderRetraining ()}
				</div>
				<div className="mb-2 bg-empinfo p-1">
					<h6>Награды</h6>
					{state.rewardRecords.map (record => renderReward (record.id))}
					{!state.hasDeclaration && <div className="text-right mb-1">
						<Action label={state.rewardCreationVisible ? "Закрыть" : "Открыть"} icon={`fas fa-angle-double-${state.rewardCreationVisible ? "up" : "down"}`} onClick={() => dispatch ({rewardCreationVisible: !state.rewardCreationVisible})} />
					</div>}
					{state.rewardCreationVisible && renderReward ()}
				</div>
				<div className="mb-2 bg-empinfo p-1">
					<h6>Сведения о дисциплинарной, материальной, гражданско-правовой, административной и уголовной ответственности</h6>
					<Form
						ref={formRefs ["emp-5"]} store={store} rsc="record" rid={state.emp} mid="emp"
						className="border mb-1" hideLogButton={hideLogButton} disabled={!!state.hasDeclaration}
						hideButtons={store.roleCode == "observer"} hideSaveButton
					>
						<Field property="noCrime" onChange={({value}) => dispatch ({noCrime: value})} />
					</Form>
					{state.noCrime ? null : <div>
						{state.crimeRecords.map (record => renderCrime (record.id))}
						{!state.hasDeclaration && <div className="text-right mb-1">
							<Action label={state.crimeCreationVisible ? "Закрыть" : "Открыть"} icon={`fas fa-angle-double-${state.crimeCreationVisible ? "up" : "down"}`} onClick={() => dispatch ({crimeCreationVisible: !state.crimeCreationVisible})} />
						</div>}
						{state.crimeCreationVisible && renderCrime ()}
					</div>}
				</div>
				<div className="mb-2 bg-empinfo p-1">
					<h6>Владение иностранными языками</h6>
					{state.languageRecords.map (record => renderLanguage (record.id))}
					{!state.hasDeclaration && <div className="text-right mb-1">
						<Action label={state.languageCreationVisible ? "Закрыть" : "Открыть"} icon={`fas fa-angle-double-${state.languageCreationVisible ? "up" : "down"}`} onClick={() => dispatch ({languageCreationVisible: !state.languageCreationVisible})} />
					</div>}
					{state.languageCreationVisible && renderLanguage ()}
				</div>
				<div className="mb-2 bg-empinfo p-1">
					<h6>Сведения об участии в выборных органах государственной власти, муниципального управления</h6>
					<Form
						ref={formRefs ["emp-6"]} store={store} rsc="record" rid={state.emp} mid="emp"
						className="border mb-1" hideLogButton={hideLogButton} disabled={!!state.hasDeclaration}
						hideButtons={store.roleCode == "observer"} hideSaveButton
					>
						<Field property="noElection" onChange={({value}) => dispatch ({noElection: value})} />
					</Form>
					{state.noElection ? null : <div>
						{state.electionRecords.map (record => renderElection (record.id))}
						{!state.hasDeclaration && <div className="text-right mb-1">
							<Action label={state.electionCreationVisible ? "Закрыть": "Открыть"} icon={`fas fa-angle-double-${state.electionCreationVisible ? "up": "down"}`} onClick={() => dispatch ({electionCreationVisible: !state.electionCreationVisible})} />
						</div>}
						{state.electionCreationVisible && renderElection ()}
					</div>}
				</div>
				<div className="mb-2 bg-empinfo p-1">
					<h6>Сведения о работе</h6>
					{state.jobRecords.map (record => renderJob (record))}
					{!state.hasDeclaration && <div className="text-right mb-1">
						<Action label={state.jobCreationVisible ? "Закрыть" : "Открыть"} icon={`fas fa-angle-double-${state.jobCreationVisible ? "up": "down"}`} onClick={() => dispatch ({jobCreationVisible: !state.jobCreationVisible})} />
					</div>}
					{state.jobCreationVisible && renderJob ()}
					<div className={"mr-2"}>{state.jobNum > 1 ? <div className="font-weight-bold">Общий стаж {`${state.jobDays / 365 | 0} л. ${state.jobDays % 365 / 30 | 0} м. ${state.jobDays % 365 % 30 | 0} д.`}</div> : null}</div>
				</div>
				<Form
					ref={formRefs ["emp-3"]} store={store} rsc="record" rid={state.emp} mid="emp"
					className="border mb-1" hideLogButton={hideLogButton} disabled={!!state.hasDeclaration}
					hideButtons={store.roleCode == "observer"} hideSaveButton
				>
					<div className="border mb-2 p-2 bg-empinfo">
						<h6>Текущее место работы</h6>
{/*
						<div className="row">
							<div className="col-4">
								<div className={state.postStrVisible ? "d-none" : "d-block"}><Field property="post" dict disabled={!!state.noJob} /></div>
								<div className={state.postStrVisible ? "d-block" : "d-none"}><Field property="postStr" disabled={!!state.noJob} /></div>
								<BooleanField label="Ручной ввод" onChange={async ({value}) => {
									if (!value) {
										let record = await store.getRecord (state.emp);

										if (record.postStr) {
											await store.transaction (async () => {
												record.postStr = "";
												await record.sync ();
											});
										}
									}
									dispatch ({postStrVisible: value});
								}} value={state.postStrVisible} disabled={!!state.hasDeclaration || !!state.noJob} />
							</div>
							<div className="col-4">
								<div className={state.orgStrVisible ? "d-none" : "d-block"}>
									<Field property="org" dict disabled={!!state.noJob}
										   records={state.terr ? state.orgRecords.filter (record => record.terr == state.terr) : state.orgRecords} />
								</div>
								<div className={state.orgStrVisible ? "d-block" : "d-none"}><Field property="orgStr" disabled={!!state.noJob} /></div>
								<BooleanField label="Ручной ввод" onChange={async ({value}) => {
									if (!value) {
										let record = await store.getRecord (state.emp);

										if (record.orgStr) {
											await store.transaction (async () => {
												record.orgStr = "";
												await record.sync ();
											});
										}
									}
									dispatch ({orgStrVisible: value});
								}} value={state.orgStrVisible} disabled={!!state.hasDeclaration || !!state.noJob} />
							</div>
						</div>
*/}
						<div className="row">
							<div className="col-4">
								<Field property="pedExp" notNull />
							</div>
							<div className="col-4">
								<Field property="noJob" onChange={({value}) => dispatch ({noJob: value})} disabled={untilNow} />
							</div>
						</div>
					</div>
					<div className="border my-2 p-2 bg-empinfo">
						<Field property="jobFile" accept=".pdf" notNull />
					</div>
					<div className="border my-2 p-2 bg-empinfo">
						<div className="row">
							<div className="col-6">
								<RadioField
									label="" property="nomination" records={store.map.dict ["d.declaration.nomination"]} disabled={!!state.hasDeclaration}
									onChange={({value}) => dispatch ({nomination: value})}
								/>
							</div>
							<div className="col-6">
								{state.nomination == store.dict ["d.declaration.nomination"]["selfNomination"].id ? null : <Field property="nominationFile" label="Файл" notNull accept=".pdf" />}
							</div>
						</div>
					</div>
					<div className="border my-2 p-2 bg-empinfo">
						<Field property="confirmFile" notNull description="Прикрепите файл не более 25 мб" maxSize={25 * 1024 * 1024} />
						<Field property="drugsFile" notNull description="Прикрепите файл не более 25 мб" maxSize={25 * 1024 * 1024} />
						<Field property="noCrimeFile" notNull description="Прикрепите файл не более 25 мб" maxSize={25 * 1024 * 1024} />
						<Field property="programFile" notNull description="Прикрепите файл не более 25 мб" maxSize={25 * 1024 * 1024} />
					</div>
				</Form>
				{!state.hasDeclaration && <Action label={state.saving ? "Сохранение" : "Завершить формирование портфолио"} disabled={state.saving} icon="fas fa-check" onClick={onSave} />}
				{state.savingHasError ? <div className="alert alert-danger my-1">Форма содержит ошибки</div> : null}
			</div></Tab>}
 		</Tabs>
	</div>;
};
